
import { defineComponent, ref } from "vue";
import { Field } from "vee-validate";

export default defineComponent({
  name: "search-bar",
  components: {
    Field,
  },

  setup() {
    const entityInfos = ref([
      {
        id: 1,
        entity_short_name: "Augmedix Bangladesh",
      },
      {
        id: 2,
        entity_short_name: "Genex Infosys Limited",
      },
      {
        id: 3,
        entity_short_name: "Mars Solutions Limited",
      },
      {
        id: 4,
        entity_short_name: "Noble It Solution Ltd",
      },
      {
        id: 5,
        entity_short_name: "Digicon Technologies Ltd.",
      },
      {
        id: 6,
        entity_short_name: "Service Solutions Pvt. Ltd.",
      },
    ]);
    return {
      entityInfos,
    };
  },

  props: {
    isActive: Boolean,
    searchData: Function,
    batches: Object,
  },

  data() {
    return {
      batch: this.batches,
    };
  },

  // methods: {

  // },
});
